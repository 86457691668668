<template>
  <div class="container-xs">
    <AppBackHeader to="/search/summary">CHOOSE DEPARTING FLIGHT</AppBackHeader>
    <div class="trip-flights">
      <FlightCardSummary
        v-for="flight in getActive.trip.flights"
        :key="flight.id"
        :data-selected="flight.id === getActive.flight.id"
        :flight-direction="flight.departureFlight"
        :info-price="
          (flight.price.total + flight.priceDifference) /
          FLIGHT.ONE_WAY_PRICE_DIVIDER
        "
        info-label="Flight Details"
        show-card
        show-info
        @click="onSelect(flight.id)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppBackHeader from '../../../components-rf/AppBackHeader.vue'
import FlightCardSummary from '../../../components-rf/search/flights/FlightCardSummary.vue'
import { FLIGHT } from '../../../constants/FLIGHT'
import { scrollIntoView } from '../../../other/utils/scrollIntoView'

export default {
  name: 'TripFlights',
  components: { AppBackHeader, FlightCardSummary },
  mounted() {
    scrollIntoView(`[data-selected='true']`)
  },
  data() {
    return { FLIGHT }
  },
  computed: mapGetters('SearchState', ['getActive']),
  methods: {
    ...mapActions('SearchState', ['select']),
    onSelect(flightId) {
      this.select({ flightId })
      this.$router.push('/search/flight/details')
    },
  },
}
</script>

<style scoped lang="scss">
.trip-flights {
  display: grid;
  gap: 1rem;
  margin-top: 3rem;
}
</style>
